import React, { useState } from 'react';

const JobansogningApp = () => {
  const [salary, setSalary] = useState('');
  const [timeFrame, setTimeFrame] = useState('weekly');
  const [hours, setHours] = useState('');
  const [hourlyRate, setHourlyRate] = useState(null);

  const calculateHourlyRate = () => {
    const salaryNum = parseFloat(salary);
    const hoursNum = parseFloat(hours);

    if (isNaN(salaryNum) || isNaN(hoursNum) || hoursNum === 0) {
      setHourlyRate(null);
      return;
    }

    let hourlyRateCalc;
    if (timeFrame === 'weekly') {
      hourlyRateCalc = salaryNum / hoursNum;
    } else {
      // Assuming 4.33 weeks per month on average
      hourlyRateCalc = (salaryNum / 4.33) / hoursNum;
    }

    setHourlyRate(hourlyRateCalc);
  };

  return (
    <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl">
      <h2 className="text-2xl font-bold mb-4 text-blue-800">Salary Calculator</h2>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="salary">
          Total Salary (in dollars)
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="salary"
          type="number"
          value={salary}
          onChange={(e) => setSalary(e.target.value)}
          placeholder="Enter your salary"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Time Frame
        </label>
        <div>
          <label className="inline-flex items-center mr-4">
            <input
              type="radio"
              className="form-radio"
              name="timeFrame"
              value="weekly"
              checked={timeFrame === 'weekly'}
              onChange={() => setTimeFrame('weekly')}
            />
            <span className="ml-2">Weekly</span>
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              className="form-radio"
              name="timeFrame"
              value="monthly"
              checked={timeFrame === 'monthly'}
              onChange={() => setTimeFrame('monthly')}
            />
            <span className="ml-2">Monthly</span>
          </label>
        </div>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="hours">
          Hours Worked ({timeFrame === 'weekly' ? 'per week' : 'per month'})
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="hours"
          type="number"
          value={hours}
          onChange={(e) => setHours(e.target.value)}
          placeholder={`Enter hours worked ${timeFrame === 'weekly' ? 'per week' : 'per month'}`}
        />
      </div>
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        onClick={calculateHourlyRate}
      >
        Calculate Hourly Rate
      </button>
      {hourlyRate !== null && (
        <div className="mt-4 text-lg">
          Your hourly rate: <span className="font-bold">${hourlyRate.toFixed(2)}</span>
        </div>
      )}
    </div>
  );
};

export default JobansogningApp;