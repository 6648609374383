import React, { useState, useEffect } from 'react';
import { Menu } from 'lucide-react';
import JobansogningApp from './JobansogningApp';

const colorSchemes = {
  jobansogning: 'blue',
  guldbryllup: 'green',
  fodselsdag: 'orange'
};

const Header = ({ currentApp, setCurrentApp }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const baseColors = {
    blue: 'bg-blue-500 hover:bg-blue-600',
    green: 'bg-green-500 hover:bg-green-600',
    orange: 'bg-orange-500 hover:bg-orange-600'
  };

  return (
    <header className={`${baseColors[colorSchemes[currentApp]]} text-white p-4`}>
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-xl font-bold">Logo</div>
        <div className="relative">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="focus:outline-none"
          >
            <Menu size={24} />
          </button>
          {isMenuOpen && (
            <nav className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg">
              <ul>
                {Object.keys(colorSchemes).map((app) => (
                  <li key={app}>
                    <button
                      onClick={() => {
                        setCurrentApp(app);
                        setIsMenuOpen(false);
                      }}
                      className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                    >
                      {app.charAt(0).toUpperCase() + app.slice(1)}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
          )}
        </div>
      </div>
    </header>
  );
};

const Footer = () => (
  <footer className="bg-gray-800 text-white p-4">
    <div className="container mx-auto">
      <div className="grid grid-cols-3 gap-4">
        <div>
          <h3 className="font-bold mb-2">Company</h3>
          <ul>
            <li><a href="/about" className="hover:underline">About Us</a></li>
            <li><a href="/careers" className="hover:underline">Careers</a></li>
            <li><a href="/contact" className="hover:underline">Contact</a></li>
          </ul>
        </div>
        <div>
          <h3 className="font-bold mb-2">Legal</h3>
          <ul>
            <li><a href="/privacy" className="hover:underline">Privacy Policy</a></li>
            <li><a href="/terms" className="hover:underline">Terms of Service</a></li>
            <li><a href="/cookies" className="hover:underline">Cookie Policy</a></li>
          </ul>
        </div>
        <div>
          <h3 className="font-bold mb-2">Social</h3>
          <ul>
            <li><a href="https://facebook.com" className="hover:underline">Facebook</a></li>
            <li><a href="https://twitter.com" className="hover:underline">Twitter</a></li>
            <li><a href="https://instagram.com" className="hover:underline">Instagram</a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

const App = () => {
  const [currentApp, setCurrentApp] = useState('jobansogning');
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  const renderAppContent = () => {
    switch(currentApp) {
      case 'jobansogning':
        return <JobansogningApp />;
      case 'guldbryllup':
        return (
          <div className={`min-h-screen bg-green-100`}>
            <div className="container mx-auto py-8">
              <h1 className="text-4xl font-bold mb-4 text-green-800">Guldbryllup App</h1>
              <p className="text-gray-700">Placeholder for Guldbryllup app.</p>
            </div>
          </div>
        );
      case 'fodselsdag':
        return (
          <div className={`min-h-screen bg-orange-100`}>
            <div className="container mx-auto py-8">
              <h1 className="text-4xl font-bold mb-4 text-orange-800">Fødselsdag App</h1>
              <p className="text-gray-700">Placeholder for Fødselsdag app.</p>
            </div>
          </div>
        );
      default:
        return <div>App not found</div>;
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header currentApp={currentApp} setCurrentApp={setCurrentApp} />
      <main className="flex-grow">
        {renderAppContent()}
      </main>
      <Footer />
    </div>
  );
};

export default App;